<template>
  <b-row>

    <!-- Small -->
     

    <!-- Large -->
    <b-col cols="12">
      <b-form-group>
        <v-select  v-if="categories!=null"
          v-model="selectedHost"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="categories" 
          
          class="select-size-lg"
          single
        >
         <template #option="{ title ,description }">
             
             
              <span> {{ title }}</span><br/>
                    <small class="text-muted"> {{ description }} </small>
            </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store' 
import axios from '@axios'
import router from '@/router'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  created () {
    console.log("created : department picker programs");
    console.log("initial hosts");
    console.log(this.initialHost);
    this.fetchCategories()
  
  },
  props :{
    initialHost: { // Tekil bir obje olarak tanımlandı
      type: Object,
      required: false, // Eğer null ya da undefined olabiliyorsa
      default: () => null // Varsayılan olarak boş obje
    },
    initialId:null
  },

  inject: ['programinfo'],
  methods:{
     updateSelections(a) {
    
    console.log(a)
  },
         fetchCategories( ) {
      console.log("Hosts fetch");
      
      //const radioId=this.$route.params.id;


      return new Promise((resolve, reject) => {
       const radioId= router.currentRoute.params.id ;
       console.log("route.currentRoute");
       console.log(router.currentRoute);

        axios
        .get('admin/radio/hosts/?radioId='+radioId )
          .then((response)=> { 
           
         console.log("Hosts Fetched")
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
          console.log(responseData.data);
              this.categories=responseData.data;
              console.log("intial Hosts checking")
        if (this.initialHost==null||this.initialHost.length==0)
        {
            this.initialHost=null;this.categories[0];
            console.log("intial Hosts not found set to first item")
        } else
        {
          console.log("intial Hosts   found setting to  item")
          this.selectedHost=this.initialHost;
        }
          

         

            }
            )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
    initialHost: {
      immediate: true,
      handler(newValue) {
        console.log("host changed");
        this.selectedHost = newValue; // Prop güncellenirse, seçimi de güncelle
      }
    },
    selectedHost: function (newVal,coldVal) {
    
console.log(this.initialHost);
console.log(this.selectedHost);

     this.$emit("selectionChanged", newVal);


  },
  programinfo:   {
        handler:function (n, o)   {
          console.log('new host in watch in departmentpicker: ', n);

                  if (n==null||n==undefined)
                  {
                    this.selectedHost=null
                  } else
                  { 
                    console.log('new host: ', n.currentCategory.Host);
                  this.selectedHost = n.currentCategory.Host;
                  }
        

        },
        deep: true
    }
},
  data() {
    return {
      categories:null,
      
      selectedHost: this.initialHost,
    }
  }
   

}
</script>